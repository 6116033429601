var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('b-modal',{attrs:{"active":_vm.active,"can-cancel":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{staticClass:"modal-card",on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Create a new user ")])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"firstname","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Firstname","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"lastname","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Lastname","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"email","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"E-mail","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"username","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Username","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)]}}],null,true)})],1)]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"generic_role","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Generic role","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-role',{attrs:{"disabled":_vm.isLoading,"append-to-body":""},model:{value:(_vm.user.generic_role),callback:function ($$v) {_vm.$set(_vm.user, "generic_role", $$v)},expression:"user.generic_role"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('sp-link',{attrs:{"tag":"b-button","disabled":_vm.isLoading,"to":{name: 'users.list'},"label":_vm.$t('close'),"icon-left":"times"}}),_c('b-button',{attrs:{"disabled":! valid || _vm.isLoading,"label":_vm.$t('create'),"icon-left":"save","type":"is-primary"},on:{"click":function($event){return _vm.save()}}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }