



















































































































































import Base from '@/mixins/Base.vue';
import { IUser, IUserResponse } from '@/interfaces/user';

const component = Base.extend({
    data() {
        return {

            active: false,
            user: {} as IUser,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save() : void{
            const loginUrl: string = `${window.location.protocol}//${window.location.host}${this.$router.resolve({ name: 'auth.login' }).href}`;

            this.post<IUserResponse>('users', {
                username: this.user.username,
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                email: this.user.email,
                generic_role: this.user.generic_role ? this.user.generic_role.name : null,
                login_url: loginUrl,
            }).then(({ data }) => {
                this.$router.push({ name: 'users.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
